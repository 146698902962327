<template>
    <div>
        <v-toolbar flat class="pl-4">
            <v-toolbar-title style="line-height:20px;padding-top: 6px;">
                <strong>Login</strong>
                <div style="font-size:small">Enter email &amp; password to verify</div>
            </v-toolbar-title>
        </v-toolbar>

        <div class="pa-6 pt-8">

            <v-card class="pa-4">
                 <v-form>
                    <label for="email" font-small>Email</label>
                    <v-text-field :disabled="loading" hide-details id="email" name="email" type="email" v-model="emailId" class="pa-0" required @input="fn_validateEmail"></v-text-field>
                    <span font-small style="color:red" v-if="!isValid">Please enter a valid email</span>

                    <div class="mt-8">
                        <label for="email" font-small>Invitation Password</label>
                        <v-text-field hide-details :disabled="loading" type="tel" name="code" v-model="code" class="pa-0" required @input="fn_isCodeValid"></v-text-field>
                        <span font-small style="color:red" v-if="!isCodeValid">Please enter 6 digit invitation password</span>
                    </div>

                    <v-btn class="mt-8" style="color:#ffffff" block color="blue-grey darken-3" height="48" @click="fn_getDeviceDetails" :disabled="loading || !isCodeValid">
                        {{loading ? 'Please wait...' : 'Verify'}}
                    </v-btn>

                </v-form>
            </v-card>

            <div class="pt-4 font-small" style="position:fixed;bottom: 16px;">By login, I accept <span @click="$root.fn_openExternalPage('https://clubtrip.app/terms')"><strong>Terms &amp; Conditions</strong></span> & <span @click="$root.fn_openExternalPage('https://clubtrip.app/policy')"><strong>Privacy Policy</strong></span></div>
           
        </div>
    </div>
</template>

<script>

export default{
    data(){
        return{
            code:null,
            loading:false,
            emailId:null,
            isValid:true,
            isCodeValid:true
        }
    },
    methods: {

        fn_initActions: function(tripId){
            console.log('Im init');
            console.log(tripId);

            // AndroidRoutingTest != undefined ? AndroidRoutingTest.onPageLoadCompleted('1', JSON.stringify({})) : '';

            if(tripId){
                this.$root.tripSiteId = tripId;
                this.$root.fn_setTheme(tripId)
                // this.$router.replace({path:'/homepage'});
                // this.$root.fn_getLocalData('AttendeeInfo', response=>{
                //     this.$root.tripOnLoadData.AttendeeInfo = response
                // });
            }
            else{
                this.$root.fn_pageLoadComplete();
            }

            // if(tripId == 7){
            //     this.$router.replace({path:'/platinumclub'});
            // }
            // else if(tripId == 8){
            //     this.$router.replace({path:'/gcamericas'});
            // }
            // else if(tripId == 9){
            //     this.$router.replace({path:'/gcasia'});
            // }
            // else if(tripId == 10){
            //     this.$router.replace({path:'/gcemea'});
            // }
            // else if(tripId == 11){
            //     this.$router.replace({path:'/demotrip'});
            // }
            
            // this.fn_pageLoadComplete();
            
        },

        fn_validateEmail: function(value){
            let emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
            this.isValid = emailCheck.test(value);
        },

        fn_isCodeValid: function(){
            this.isCodeValid = !this.code || this.code.length != 6 ? false : true;
        },  

        fn_getDeviceDetails: function(){
            if(!this.isValid){
                this.$root.fn_showToast('Please enter a valid email');
                return;
            }

            let self = this;
            this.loading = true;
            this.getData("DeviceDetail", null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_verifyInvitationCode(response);
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        },

        fn_verifyInvitationCode: function(deviceDetails){
            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                return;
            }

            this.loading = true;
            
            let self = this;
            let dataObj = {};
            dataObj.EmailAddress = this.emailId;
            dataObj.Code = this.code;
            dataObj.DeviceDetails = deviceDetails;

            this.postServerData('VerifyInviteCode', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        // self.requestId = response.RequestID;
                        let data = JSON.parse(response);
                        self.fn_loginToFirebase(data.Token);
                    }, 
                    onError: function(e){
                        console.log(e);
                        self.loading = false;
                        let error = JSON.parse(e);
                        let toastMsg = error && error.Message ? error.Message : 'exceptiona';
                        self.$root.fn_showToast(toastMsg);
                    }, 
                }
            );
        },

        fn_loginToFirebase: function(token){
            let self = this;
            this.getData("FirebaseLoginUsingCustomToken", token, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$router.replace({path:'/trips'});
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        }

    },
    mounted() {
        window.js_globals.fn_initActions = this.fn_initActions
    },
}

</script>