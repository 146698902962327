<template>
    <div>
        <!-- <v-alert :color="$root.themeColor" style="border-radius:0;position:sticky;top:0;z-index:1" dense type="info" :value="true">
            Choose attendee to chat with
        </v-alert> -->
        <v-toolbar class="attendeelist_toolbar" :color="$root.themeColor" flat style="color:#fff" extended extension-height="62">
            <v-btn :color="$root.themeColor" icon class="mr-4 white--text" @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Attendees</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <div class="pr-4 pt-0 include_guest_btn"><v-switch hide-details @change="fn_isShowGuest" v-model="IsShowGuest" label="Include Guest"></v-switch></div> -->
            <!-- <v-badge :content="unreadMessageCount" :value="unreadMessageCount" color="#f69220" overlap>
                  <v-icon color="white" @click="$root.fn_openChatView">chat</v-icon>
            </v-badge> -->
            <!-- <div slot="extension" solo><v-switch @change="fn_isShowGuest" v-model="IsShowGuest" label="Include Guest"></v-switch></div> -->
            <v-text-field dense grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
            
        </v-toolbar>
  
        <div>
  
          <template v-if="!error">
            <v-list class="pa-0" three-line>
              <template v-for="(item, index) in winners">
                <v-list-item :key="index" class="zindex2 attendeelist_customformatting">
  
                  <v-list-item-avatar @click="fn_gotoChatPage(item.AttendeeRegistrationID)" class="grey lighten-3" style="align-self: center;">
                      <!-- {{fn_abbreviatedName(item.WinnerName)}} -->
                      <template v-if="item.ImageUrl != null"><v-img alt="winnerimage" :src="item.ImageUrl" lazy-src="../assets/person.svg"></v-img></template>
                      <template v-else>{{fn_abbreviatedName(item.WinnerName)}}</template>
                      
                  </v-list-item-avatar>
  
                  <v-list-item-content @click="fn_gotoChatPage(item.AttendeeRegistrationID)">
                      <v-list-item-title v-text="item.WinnerName"></v-list-item-title>
                      <v-list-item-subtitle v-if="item.AttendeeType == 2 && item.PrimaryAttendeeName">Guest of {{item.PrimaryAttendeeName}}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="item.JobTitle">{{item.JobTitle}}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="item.AreaName">{{item.AreaName}}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="item.Subsidiary && item.AreaName != item.Subsidiary">{{item.Subsidiary}}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="item.RegistrationFormType != 1 && item.Organization != null">{{item.Organization}}</v-list-item-subtitle>
  
                  </v-list-item-content>
  
                    <!-- <v-list-item-icon v-if="item.CanChat" style="margin: auto;" @click="$root.fn_openChatView('instantchat', item.AttendeeID)">
                      <v-icon>mdi-message-outline</v-icon>
                    </v-list-item-icon> -->
                  </v-list-item>
  
                  <v-divider inset :key="index+'divider'" v-if="index != winners.length - 1"></v-divider>
              </template>
                
  
                <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                  <div slot="no-more"></div>
                  <div slot="no-results" class="pa-4 light-opacity-color">No attendee found!</div>
                </infinite-loading>
            </v-list>
          </template>
  
          <template v-else>
            <div style="text-align:center;" class="pa-4">
                <p>Something went wrong. Please try again.</p>
                <br>
                <v-btn outlined @click="fn_debounceSearch"><v-icon left>refresh</v-icon> Retry</v-btn>
            </div>
          </template>
        </div>
  
        <v-dialog v-model="contentDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
          <v-card>
            <v-card-title :style="{ backgroundColor: $root.themeColor}" :color="$root.themeColor"  class="title pb-4 pl-4 white--text" style="flex-wrap:nowrap;word-break: break-word;">
                <v-btn :color="$root.themeColor" icon class="mr-4 white--text" @click="contentDialog = false">
                    <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                </v-btn>
                Gift Details
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="scrollable-content form_dialog_text pa-0 platinumclub_custom_formatting">
              <template v-if="error">
                <div style="text-align:center;" class="pa-4">
                    <p>Something went wrong. Please try again.</p>
                    <br>
                    <v-btn outlined @click="fn_getAttendeeInfo"><v-icon left>refresh</v-icon> Retry</v-btn>
                </div>
              </template>

              <template v-if="loading">
                  <div class="pa-8">
                      <v-img width="120" style="margin:auto" src="../assets/giphy.gif"></v-img>
                  </div>
              </template>

              <template v-else>
                <v-card flat class="mt-0" v-if="ItemObj">
                  <v-card-text>
                    <v-card class="height100 details_box" v-if="ItemObj.PrimaryAttendee">
                      <v-card-title style="line-height: 1;" class="dialog_toolbar gift_title">{{ItemObj.PrimaryAttendee.AttendeeName}}</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="pl-2 pb-2 pr-2 pt-2">
                          <div class="form_content height100">
                              <div class="page_content_fields pl-3 pr-2 pt-2 pb-2" style="font-size: 12pt;">
                                  <!-- <p class="mb-1" v-if="ItemObj.PrimaryAttendee.AttendeeName"><b>Name: </b>{{ItemObj.PrimaryAttendee.AttendeeName}}</p> -->
                                  <p class="mb-2" v-if="ItemObj.PrimaryAttendee.CategoryName"><b>Attendee Type: </b>{{ItemObj.PrimaryAttendee.AttendeeType}}</p>
                                  <ol v-if="ItemObj.PrimaryAttendee.Gifts && ItemObj.PrimaryAttendee.Gifts.length>0">
                                    <li class="pb-1" v-for="(gift, key) in ItemObj.PrimaryAttendee.Gifts" :key="key">
                                      <b>{{ gift.Title }}</b> <span> - {{ gift.IsPrimaryGiftCollected ? 'Collected' : 'Not Collected' }}</span>
                                      <div class="pt-1 pb-2">
                                        <a style="font-size: 12pt;" v-if="gift.IsPrimaryGiftCollected" @click="fn_markAttendenceType(ItemObj.PrimaryAttendee.AttendeeID, false, ItemObj.PrimaryAttendee.AttendeeName, gift.GiftID, 'Primary', gift.Title)">Cancel Collection</a>
                                        <a style="font-size: 12pt;" v-else @click="fn_markAttendenceType(ItemObj.PrimaryAttendee.AttendeeID, true, ItemObj.PrimaryAttendee.AttendeeName, gift.GiftID, 'Primary', gift.Title)">Collect Now</a>
                                      </div>
                                    </li>

                                  </ol>
                              </div>
                          </div>
                      </v-card-text>
                    </v-card>
                    <v-card class="height100 mt-4 details_box" v-if="ItemObj.GuestAttendee">
                        <v-card-title style="line-height: 1;" class="dialog_toolbar gift_title">{{ItemObj.GuestAttendee.AttendeeName}}</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="pl-2 pb-2 pr-2 pt-2">
                            <div class="form_content height100">
                                <div class="page_content_fields pl-3 pr-2 pt-2 pb-2" style="font-size: 12pt;">
                                    <!-- <p class="mb-1" v-if="ItemObj.GuestAttendee.AttendeeName"><b>Name: </b>{{ItemObj.GuestAttendee.AttendeeName}}</p> -->
                                    <p class="mb-2" v-if="ItemObj.GuestAttendee.CategoryName"><b>Attendee Type: </b>{{ItemObj.GuestAttendee.AttendeeType}}</p>
                                    <ol v-if="ItemObj.GuestAttendee.Gifts && ItemObj.PrimaryAttendee.Gifts.length>0">
                                      <li class="pb-1" v-for="(gift, key) in ItemObj.GuestAttendee.Gifts" :key="key">
                                        <b>{{ gift.Title }}</b><span> - {{ gift.IsGuestGiftCollected ? 'Collected' : 'Not Collected' }}</span>
                                        <div class="pt-1 pb-2">
                                          <a style="font-size: 12pt;" v-if="gift.IsGuestGiftCollected" @click="fn_markAttendenceType(ItemObj.GuestAttendee.AttendeeID, false, ItemObj.GuestAttendee.AttendeeName, gift.GiftID, 'Guest', gift.Title)">Cancel Collection</a>
                                          <a style="font-size: 12pt;" v-else @click="fn_markAttendenceType(ItemObj.GuestAttendee.AttendeeID, true, ItemObj.GuestAttendee.AttendeeName, gift.GiftID, 'Guest', gift.Title)">Collect Now</a>
                                        </div>
                                      </li>
                                    </ol>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>

                <!-- <v-footer v-if="$root.Envirenment != 3" class="pa-3" style="position: fixed;width: 100%;bottom: 0;background:#fff">
                    <v-btn block color="primary" dark @click="$root.fn_openChatView('instantchat', attendeeInfo.AttendeeID)">Start Chat</v-btn>
                </v-footer> -->
              </template>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="ConfimationDialog" persistent>
          <v-card>
            <v-card-title class="text-h6">Confirmation</v-card-title>
                <!-- <v-divider></v-divider> -->
            <v-card-text>Are you sure you want to Cancel <b>{{ GiftName }}</b> Collection for <b>{{ AttendeeName }}</b>?</v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="$root.themeColor" tile class="btn-white pl-3 pr-3 confirmation_btn white--text" @click="fn_cancelCollectionGift">Confirm</v-btn>
                <v-btn :color="$root.themeColor" outlined tile class="btn-white ml-3 mr-4" @click="ConfimationDialog = false">
                    <v-icon>close</v-icon> Close
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="Loadingdialog" content-class="loading-dialog" persistent max-width="250">
            <v-card class="pa-2" height="75">
                <div class="text-center">
                    <v-progress-circular :size="40" color="primary" indeterminate></v-progress-circular>
                    <div class="loading-msg pl-3">{{LoadingMessage}}</div> 
                </div>
            </v-card>
        </v-dialog>
  
        
    </div>
  </template>
  
  <script>
  import InfiniteLoading from 'vue-infinite-loading';
  import axios from 'axios';
  // import dialogAttendeeInfo from '../winner_attendee_profile'
  // import { CometChat } from "@cometchat-pro/chat";
  const moment = require('moment');
  var controller = null;
  
  // const controller = new AbortController();
  
    export default {
      data: () => ({
        loading:false,
        winners:[],
        infiniteId: +new Date(),
        pageOffset:0,
        searchValue:'',
        timeoutid:null,
        error:false,
        unreadMessageCount:0,
        IsShowGuest: true,
        contentDialog: false,
        ItemObj: {
          "PrimaryAttendee": {
            "AttendeeName": "Waseem Khan",
            "GuestName": "Intekhab Khan",
            "RegistrationCode": "PC000005171",
            "Alias": "v-waseemkhan",
            "JobTitle": "Manager",
            "Segment": "Enterprise Commercial",
            "ImageUrl": null,
            "Achievements": null,
            "Organization": "Industry & Partner Sales",
            "AreaName": "United States",
            "Subsidiary": "United States",
            "Period": "FY24",
            "gifts": [
                {
                    "GiftID": "b8dab27b-9958-492f-8f4a-3557912a3fef",
                    "TripSiteID": 20,
                    "Title": "Signature Gift",
                    "GiftDescription": null,
                    "IsAttendeeOnly": false,
                    "CollectedForBoth": null,
                    "CollectedForBothTime": null,
                    "CollectedForAttendee": null,
                    "CollectedForAttendeeTime": null,
                    "CollectedForGuest": null,
                    "CollectedForGuestTime": null,
                    "Location": null,
                    "DisplayTime": null,
                    "CustomFieldName": null,
                    "IsGift": true,
                    "SortOrder": 2,
                    "TagID": null,
                    "CategoryIDList": null
                },
                {
                    "GiftID": "7584fcbd-117b-48cb-8b68-d84ac177dbfd",
                    "TripSiteID": 20,
                    "Title": "Welcome Gift",
                    "GiftDescription": null,
                    "IsAttendeeOnly": false,
                    "CollectedForBoth": true,
                    "CollectedForBothTime": "2024-10-11T02:49:02.923",
                    "CollectedForAttendee": null,
                    "CollectedForAttendeeTime": null,
                    "CollectedForGuest": true,
                    "CollectedForGuestTime": "2024-10-11T02:49:14.64",
                    "Location": null,
                    "DisplayTime": null,
                    "CustomFieldName": null,
                    "IsGift": true,
                    "SortOrder": 1,
                    "TagID": null,
                    "CategoryIDList": null
                }
            ]
          },
          "GuestAttendee": {
            "AttendeeName": "Intekhab Khan",
            "GuestName": "Intekhab Khan",
            "RegistrationCode": "PC000005171",
            "Alias": "",
            "JobTitle": "Manager",
            "Segment": "Enterprise Commercial",
            "ImageUrl": null,
            "Achievements": null,
            "Organization": "Industry & Partner Sales",
            "AreaName": "United States",
            "Subsidiary": "United States",
            "Period": "FY24",
            "gifts": [
                {
                    "GiftID": "b8dab27b-9958-492f-8f4a-3557912a3fef",
                    "TripSiteID": 20,
                    "Title": "Signature Gift",
                    "GiftDescription": null,
                    "IsAttendeeOnly": false,
                    "CollectedForBoth": null,
                    "CollectedForBothTime": null,
                    "CollectedForAttendee": null,
                    "CollectedForAttendeeTime": null,
                    "CollectedForGuest": null,
                    "CollectedForGuestTime": null,
                    "Location": null,
                    "DisplayTime": null,
                    "CustomFieldName": null,
                    "IsGift": true,
                    "SortOrder": 2,
                    "TagID": null,
                    "CategoryIDList": null
                }
            ]
          }
        },
        AttendeeRegistrationID: null,
        AttendeeName: null,
        Loadingdialog: false,
        LoadingMessage: "Saving...",
        ActionType: null,
        GiftID: null,
        ConfimationDialog: false,
        GiftName: null
      }),
      components: {
        InfiniteLoading,
        // 'dialog-attendee-info':dialogAttendeeInfo
      },
      methods:{
        fn_debounceSearch: function(){
          console.log('debounce');
          if (this.timeoutid != null){
            clearTimeout(this.timeoutid);
          }
  
          this.timeoutid = setTimeout(() => { 
            this.error = false;
            this.pageOffset = 0;
            this.winners = [];
            this.infiniteId = +new Date();
          },500);
        },

        formattedDate(date) {
          if(date)
            return moment(date).format('MMMM DD, YYYY')
        },
  
        infiniteHandler($state) { 
          this.error = false;
          this.$root.fn_getToken(token=>{
              if(token == 'error'){
                this.error = true; 
                this.$root.fn_showToast('exception');
              }
              else{
                  if(controller){
                    controller.abort();
                    console.log('Fetch aborted');
                  }
                  
                  controller = new AbortController();
  
                  var headersObj = {
                    headers: { 
                          'Authorization': 'bearer ' +  token,
                        }
                    };
  
                  let postObj = {}
                  postObj.keywords = this.searchValue;
                  postObj.IsGuestSearchEnable = this.IsShowGuest;
  
                  console.log(JSON.stringify(postObj));
  
                  // axios.post(this.$root.baseUrl + "/api/mobileapp/admin/onsiteview/attendee/list", postObj, headersObj, {timeout:30000}, {signal: controller.signal})
                  axios.post(this.$root.baseUrl + "/api/mobileapp/admin/gifting/attendees/list?offset="+this.pageOffset+"&limit=30", postObj, headersObj, {timeout:30000}, {signal: controller.signal})
                  .then(response => {
                      console.log(response);
                      if(response.data.count > this.winners.length){
                        for(let i=0; i<response.data.attendeeWinners.length; i++){
                            this.winners.push(response.data.attendeeWinners[i]);
                        }
                        $state.loaded();
                      }
                      else{
                        $state.complete();
                      }
                      controller = null;
                      this.fn_photoAuthThumbnail();
                      this.pageOffset = this.winners.length;
                  }).catch(e => {
                      console.log(e);
                      if(e.response.data == "Token Expired"){
                        this.fn_debounceSearch();
                      }
                      else{
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                        this.error = true;
                      }
                  })
              }
          })
          
        },
  
        fn_gotoChatPage: function(attendeeregistrationid){
          this.contentDialog = true;
          this.AttendeeRegistrationID = attendeeregistrationid;
          this.fn_getAttendeeInfo();
        },

        fn_abbreviatedName: function(str){
            if(!str){
              return ''
            }
            //let words = str.trim().split(' ');
            //words = words.replace(/ +(?= )/g,'');
            let words = str.trim().replace(/ +(?= )/g,'').split(' ');

            if(words.length > 1){
                return words[0][0].toUpperCase() + words[1][0].toUpperCase();    
            }
            else{
                return words[0][0].toUpperCase();
            }
        },

        fn_isShowGuest: function(){
          this.fn_debounceSearch();
        },

        async fn_photoAuthThumbnail() {
          let self = this;
          // this.loading = true;
          // this.error = false;

          const batchSize = 5; // Size of each batch

          // Filter winners to get those that have an ImageUrl and are not already base64
          const winnersWithImages = self.winners.filter(winner => winner.ImageUrl && !winner.ImageUrl.startsWith('data:image/'));

          // If there are no winners with valid ImageUrl, return early
          if (winnersWithImages.length === 0) {
            //   this.loading = false;
              return;
          }

          // Function to process a batch of winners
          const processBatch = async (batch) => {
              try {
                  // Prepare the list of AttendeeIds and ImageUrls to send in the POST request
                  const imagePayload = batch.map(winner => ({
                      AttendeeID: winner.AttendeeID,
                      PhotoUrl: winner.ImageUrl
                  }));

                  const token = await new Promise((resolve, reject) => {
                      this.$root.fn_getToken((token) => {
                          if (token === 'error') {
                              reject('Token error');
                          } else {
                              resolve(token);
                          }
                      });
                  });

                  console.log(token + " Token Called");

                  const headersObj = {
                      headers: { 
                          'Authorization': 'Bearer ' + token,
                      }
                  };

                  console.log(self.$root.baseUrl);

                  // Send the POST request with the array of AttendeeId and ImageUrl pairs
                  const response = await axios.post(
                      self.$root.baseUrl + '/api/mobileapp/Document/Download/Url/list', // Change to your POST endpoint
                      imagePayload,          // Send array of AttendeeId and ImageUrl pairs
                      headersObj,
                      { timeout: 30000 }
                  );

                  console.log(response.data);

                  // Update winners' ImageUrl based on the response, matching AttendeeId
                  response.data.forEach(respItem => {
                      const matchedWinner = self.winners.find(winner => winner.AttendeeID === respItem.AttendeeID);
                      if (matchedWinner) {
                          matchedWinner.ImageUrl = 'data:image/png;base64,' + respItem.Base64;
                          console.log(matchedWinner.ImageUrl);
                      }
                  });

                  self.$forceUpdate();
              } catch (e) {
                //   self.error = true;
                  console.log(e);
                  throw e; // Stop further processing on error
              }
          };

          try {
              if (winnersWithImages.length <= 5) {
                  // If the number of items with ImageUrl is less than or equal to 5, process them in one call
                  await processBatch(winnersWithImages);
              } else {
                  // Otherwise, split them into batches of 5
                  for (let i = 0; i < winnersWithImages.length; i += batchSize) {
                      const batch = winnersWithImages.slice(i, i + batchSize);
                      await processBatch(batch); // Process each batch sequentially
                  }
              }
          } catch (error) {
              console.log('Batch processing failed', error);
          } finally {
            //   this.loading = false;
              this.error = false;
          }
        },

        getDietaryRequirement(dietry, item) {
          if (dietry === 'Other') {
              return 'Other: ' + item.OtherDietaryRequirements;
          }
          else {
              return dietry;
          }
        },

        showDietaryRequirement(item) {
          if (item.DietaryRequirements && item.DietaryRequirements.length > 0 && item.HalalCertified) {
              return true
          }
          else if (item.DietaryRequirements && item.DietaryRequirements.length > 0 && item.IndianVegeterian) {
              return true
          }
          else if (item.DietaryRequirements && item.DietaryRequirements.length > 0 && item.KosherCertified) {
              return true
          }
          else if (item.DietaryRequirements && item.DietaryRequirements.length > 0 && item.AllergyDetail) {
              return true
          }
          else {
              return false
          }
        },

        formatDietaryRequirements(personalConsiderations) {
          const { HalalCertified, KosherCertified, IndianVegeterian, AllergyDetail } = personalConsiderations;

          // Start with the base dietary requirements
          let requirements = [];

          // Add additional requirements
          if (HalalCertified !== null) {
              requirements.push(HalalCertified === true ? 'Required Halal Certified Meals: Yes' : 'Halal Certified Meals: No');
          }

          if (IndianVegeterian !== null) {
              requirements.push(IndianVegeterian === true ? 'Indian Vegetarian Meals: Yes' : 'Indian Vegetarian Meals: No');
          }

          if (KosherCertified !== null) {
              requirements.push(KosherCertified === true ? 'Required Kosher Certified Meals: Yes' : 'Kosher Certified Meals: No');
          }

          if (AllergyDetail) {
              requirements.push('Allergy: ' + AllergyDetail);
          }

          // Filter out any null or undefined values (just in case)
          return requirements.filter(item => item);
        },

        fn_getAttendeeInfo: function(){
          this.ItemObj = null;
          this.AttendeeName = null;
          this.ActionType = null;
          this.GiftID = null;
          this.GiftName = null;
          this.contentDialog = true;
          this.loading = true;
          this.error = false;
          this.$root.fn_getToken(token=>{
              if(token == 'error'){
              this.loading = false;
              this.error = true;
              this.$root.fn_showToast('exception');
              }
              else{
                  var headersObj = {
                  headers: {
                          'Authorization': 'bearer '+token,
                      }
                  };

                  axios.get(this.$root.baseUrl + "/api/mobileapp/admin/gifting/attendee/" + this.AttendeeRegistrationID + "/view", headersObj, {timeout:30000})
                  .then(response => {
                      console.log(response);
                      this.loading = false;
                      this.ItemObj = response.data;
                  }).catch(e => {
                      console.log(e);
                      this.loading = false;
                      this.error = true;
                      let toastMsg = e && e.message ? e.message : 'exception';
                      this.$root.fn_showToast(toastMsg);
                  })
              }
          })
        },

        fn_markAttendenceType: function(attendeeid, ismarked, attendeename, giftid, actiontype, giftname){
            this.AttendeeID = attendeeid;
            this.IsMarked = ismarked;
            this.AttendeeName = attendeename;
            this.GiftID = giftid;
            this.ActionType = actiontype;
            this.GiftName = giftname;
            if(ismarked)
                this.fn_collectGift();
            else
                this.ConfimationDialog = true;
        },

        fn_collectGift: function(){
          this.Loadingdialog = true;
          this.error = false;
          let self = this;

          this.$root.fn_getToken(token=>{
              if(token == 'error'){
                  self.Loadingdialog = false;
                  self.error = true;
                  self.$root.fn_showToast('exception');
              }
              else{
                  var headersObj = {
                      headers: { 
                          'Authorization': 'bearer '+token,
                      }
                  };

                  var getUrl = self.$root.baseUrl + '/api/mobileapp/admin/gifting/attendee/' + self.AttendeeID + '/mark/' + this.GiftID;
                  axios.get(getUrl, headersObj, {timeout:30000})
                  .then(response => {
                      // self.activityDetailObj =  response.data;
                      self.fn_updateData();
                      console.log(response.data);
                      self.Loadingdialog = false;
                      self.$forceUpdate();
                  }).catch(e => {
                      // self.fn_stopUnmarking(attendeeid, ismarked);
                      self.Loadingdialog = false;
                      // self.error = true;
                      let toastMsg = e && e.message ? e.message : 'exception';
                      self.$root.fn_showToast(toastMsg);
                      console.log(e);
                  })
              }
          })    
        },

        fn_cancelCollectionGift: function(){
          this.Loadingdialog = true;
          this.error = false;
          let self = this;

          this.$root.fn_getToken(token=>{
              if(token == 'error'){
                  self.Loadingdialog = false;
                  self.error = true;
                  self.$root.fn_showToast('exception');
              }
              else{
                  var headersObj = {
                      headers: { 
                          'Authorization': 'bearer '+token,
                      }
                  };

                  var getUrl = self.$root.baseUrl + '/api/mobileapp/admin/gifting/attendee/' + self.AttendeeID + '/unmark/' + this.GiftID;
                  axios.get(getUrl, headersObj, {timeout:30000})
                  .then(response => {
                      // self.activityDetailObj =  response.data;
                      self.fn_updateData();
                      self.ConfimationDialog = false;
                      console.log(response.data);
                      self.Loadingdialog = false;
                      self.$forceUpdate();
                  }).catch(e => {
                      // self.fn_stopUnmarking(attendeeid, ismarked);
                      self.Loadingdialog = false;
                      // self.error = true;
                      let toastMsg = e && e.message ? e.message : 'exception';
                      self.$root.fn_showToast(toastMsg);
                      console.log(e);
                  })
              }
          })    
        },

        fn_updateData: function(){
          if(this.ActionType == 'Primary'){
            for(let i in this.ItemObj.PrimaryAttendee.Gifts){
              if(this.ItemObj.PrimaryAttendee.Gifts[i].GiftID == this.GiftID){
                if(this.IsMarked == true)
                  this.ItemObj.PrimaryAttendee.Gifts[i].IsPrimaryGiftCollected = true;
                else
                  this.ItemObj.PrimaryAttendee.Gifts[i].IsPrimaryGiftCollected = null;
                console.log(this.ItemObj.PrimaryAttendee.Gifts[i].IsPrimaryGiftCollected);
                break
              }
            }
          }
          else{
            for(let i in this.ItemObj.GuestAttendee.Gifts){
              if(this.ItemObj.GuestAttendee.Gifts[i].GiftID == this.GiftID){
                if(this.IsMarked == true)
                  this.ItemObj.GuestAttendee.Gifts[i].IsGuestGiftCollected = true;
                else
                  this.ItemObj.GuestAttendee.Gifts[i].IsGuestGiftCollected = null;
                break
              }
            }
          }
        }
  
      }
    }
  </script>