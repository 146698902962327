<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>Gifting Details</v-toolbar-title>
        </v-toolbar>

        <div>

            <!-- {{attendeeInfo}} -->

            <template v-if="error">
                <div style="text-align:center;" class="pa-4">
                    <p>Something went wrong. Please try again.</p>
                    <br>
                    <v-btn outlined @click="fn_getToken"><v-icon left>refresh</v-icon> Retry</v-btn>
                </div>
            </template>

            <template v-if="loading">
                <div class="pa-8">
                    <v-img width="120" style="margin:auto" src="../assets/giphy.gif"></v-img>
                </div>
            </template>

            <template v-else>
                <v-card flat class="mt-4" v-if="attendeeInfo">
                    <v-img
                        style="border-radius:50%;margin:auto"
                        width="120"
                        height="120"
                        :src="attendeeInfo.ProfilePicture"
                        v-if="attendeeInfo.ProfilePicture"
                    ></v-img>

                    <div center-align v-else style="background:#f0f0f0;width:120px;height:120px;margin:auto;border-radius: 50%;padding:16px">
                        <v-img
                        style="margin:auto;opacity: 0.6;"
                        width="80"
                        height="80"
                        src="../assets/person.svg"
                    ></v-img>
                    </div>

                    <template v-if="attendeeInfo.ProfileDetails">
                        <div class="title pl-4 pt-4 pr-4 pb-1 center-align">{{attendeeInfo.ProfileDetails.AttendeeName}}</div>
                        <div class="center-align font-small pb-4" v-if="attendeeInfo.ProfileDetails.JobTitle">{{attendeeInfo.ProfileDetails.JobTitle}}</div>

                        <div class="center-align">
                            <v-chip small label>
                                <strong>{{isDetailsUpdated ? 'Collected' : 'Collecting'}} for {{attendeeInfo.RequestedForBoth ? 'Self & Guest' : attendeeInfo.RequestedForAttendee ? 'Self' : 'Guest'}}</strong>
                            </v-chip>
                        </div>
                    </template>

                    <!-- <v-divider style="margin-right:25%;margin-left:25%;"></v-divider> -->

                    <v-card flat color="blue-grey darken-3" dark min-height="124" class="ma-4 mt-6" style="border-radius:10px">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <v-card-title class="text-h5" style="word-break:break-word">{{attendeeInfo.Title}}</v-card-title>
                                <v-card-subtitle class="pt-0 pb-0" v-html="attendeeInfo.GiftDescription"></v-card-subtitle>
                                <v-card-subtitle class="pt-0 pb-0" v-html="attendeeInfo.Location"></v-card-subtitle>
                                <v-card-subtitle class="pt-0 pb-0" v-html="attendeeInfo.DisplayTime"></v-card-subtitle>

                                <v-card-actions class="pl-0 pb-3" v-if="isDetailsUpdated">
                                    <v-chip class="mt-4" color="white" text-color="black" label style="border-top-left-radius:0 !important;border-bottom-left-radius:0 !important">
                                        Collected
                                    </v-chip>
                                </v-card-actions>
                            </div>

                            <v-avatar class="ma-4" size="80" tile>
                                <v-icon style="font-size:64px">redeem</v-icon>
                            </v-avatar>
                        </div>
                    </v-card>

                    

                </v-card>

                <div class="ma-4" v-if="!error && !isDetailsUpdated">
                    <v-btn block style="border-radius:10px" :color="$root.themeColor" outlined :disabled="confirming" @click="fn_getToken('confirm')">
                        {{confirming ? 'Verfying Details...' : 'Confirm'}}
                    </v-btn>
                </div>
            </template>
            
            
        </div>

        <div v-if="isDetailsUpdated">
            <v-card flat class="ma-4 mt-8">
                <v-btn class="mt-4" block style="border-radius:10px" :color="$root.themeColor" outlined @click="fn_checkCameraPermission">
                    <v-icon left>qr_code_scanner</v-icon>Scan Another QR Code
                </v-btn>

                <v-btn class="mt-4" block style="border-radius:10px" :color="$root.themeColor" outlined @click="$router.go(-1)">
                    Go Back
                </v-btn>
            </v-card>
        </div>

    </div>
</template>

<script>
import axios from 'axios';

    export default{
        data(){
            return{
                loading:false,
                error:false,
                attendeeInfo:null,
                confirming:false,
                isDetailsUpdated:false,
                requestId:null
            }
        },
        methods:{

            fn_checkCameraPermission: function(){
                let self = this;
                this.getData('CheckCameraPermission', "CameraPermission", 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.fn_scanQR();
                        },
                        onError: function(error){
                            console.log(error);
                        }
                    }
                ); 
            },

            fn_scanQR: function(){
                let self = this;
                this.getData('OpenScanner', null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.isDetailsUpdated = false;
                            self.requestId = response;
                            self.fn_getToken();
                        },
                        onError: function(error){
                            console.log(error);
                            this.$root.fn_showToast("Unable to open scanner");
                        }
                    }
                );
            },

            fn_getToken: function(type){

                type === 'confirm' ? this.confirming = true : this.loading = true;
                this.error = false;

                let self = this;
                this.getData("FirebaseToken", null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            type === 'confirm' ? self.fn_confirmDetails(response) : self.fn_getDetails(response);
                        },
                        onError: function(error){
                            console.log(error);
                            self.error = false;
                            self.loading = false;
                            self.confirming = false;
                            self.$root.fn_showToast("exception");
                        }
                    }
                );
            },

            fn_getDetails: function(token){

                if(!navigator.onLine){
                    this.$root.fn_showToast("Internet not available");
                    return;
                }

                this.loading = true;
                this.error = false;

                var headersObj = {
                    headers: { 'Authorization': 'bearer '+token,}
                };

                axios.get(this.$root.baseUrl + "/api/mobileapp/admin/gifting/"+this.requestId+"/getDetails", headersObj, {timeout:30000}).then(response => {
                    this.loading = false;
                    console.log(response);
                    this.attendeeInfo = response.data;
                }).catch(e => {
                    this.loading = false;
                    this.error = true;
                    console.log(e);
                    this.$root.fn_showToast("Something went wrong. Try again");
                })
            },

            fn_confirmDetails: function(token){

                if(!navigator.onLine){
                    this.$root.fn_showToast("Internet not available");
                    return;
                }

                this.confirming = true;

                var headersObj = {
                    headers: { 'Authorization': 'bearer '+token,}
                };

                // this.$root.fn_showToast('Verifying details...');

                axios.get(this.$root.baseUrl + "/api/mobileapp/admin/gifting/"+this.requestId+"/qrcodescan", headersObj, {timeout:30000}).then(response => {
                    this.confirming = false;
                    console.log(response);
                    this.isDetailsUpdated = true;
                    this.$root.fn_showToast('Details Updated');
                    // this.$router.go(-1);
                    // this.attendeeInfo.ProfileDetails = JSON.parse(this.attendeeInfo.ProfileDetails);
                }).catch(e => {
                    this.confirming = false;
                    console.log(e);
                    this.$root.fn_showToast("Something went wrong. Try again");
                })
            }
        },
        mounted() {
            this.requestId = this.$route.query.requestId;
            this.fn_getToken();
        },
    }
</script>