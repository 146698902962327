import Vue from 'vue'

let actionQueue = [];

let platform = "";

let iosMessageInterface = null;

// action interface functions

function guidWeb() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosMessageInterface) {
    platform = "ios"
    iosMessageInterface = window.webkit.messageHandlers.iosMessageInterface;
}
else if (typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null) {
    platform = "android"
}
else {
    platform = "web"
}

Vue.prototype.getData = function(action, itemId, callbackEvents) {
    //alert('in');
    let obj = {};
    obj.name = action;
    obj.itemId = itemId;
    obj.method = "getData";
    obj.callBackId = guidWeb();
    pushAction(obj.callBackId, action, callbackEvents);
    sendRequest(obj);
}

Vue.prototype.getFilterData = function(action, itemId, filterObject, callbackEvents) {
    //alert('in');
    let obj = {};
    obj.name = action;
    obj.itemId = itemId;
    obj.filterObject = JSON.stringify(filterObject);
    obj.method = "getFilterData";
    obj.callBackId = guidWeb();
    pushAction(obj.callBackId, action, callbackEvents);
    sendRequest(obj);
}

Vue.prototype.postData = function(action, dataModel, callbackEvents) {

    let obj = {};
    obj.name = action;
    obj.method = "postData";
    obj.callBackId = guidWeb();
    obj.dataString = JSON.stringify(dataModel);
    pushAction(obj.callBackId, action, callbackEvents);
    sendRequest(obj);
}

// Vue.prototype.showToast = function(message){
//     if (platform == 'ios') {
//         iosMessageInterface.showToast(message);
//     }
//     else if (platform == 'android') {
//         AndroidRoutingTest.showToast(message);
//     }
// }

Vue.prototype.getServerData = function(action, dataObj, callbackEvents){
    let obj = {};
    obj.name = action;
    obj.dataString = JSON.stringify(dataObj);
    obj.method = "getServerData";
    obj.callBackId = guidWeb();
    pushAction(obj.callBackId, action, callbackEvents);
    sendRequest(obj);
}

Vue.prototype.postServerData = function(action, dataObj, callbackEvents){
    let obj = {};
    obj.name = action;
    obj.dataString = JSON.stringify(dataObj);
    obj.method = "postServerData";
    obj.callBackId = guidWeb();
    pushAction(obj.callBackId, action, callbackEvents);
    sendRequest(obj);
}

Vue.prototype.exitApp = function(){
    if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
        AndroidRoutingTest.exitApp();
    }
}

function sendRequest(dataObj) {
    if (platform == 'ios') {
        iosMessageInterface.postMessage(JSON.stringify(dataObj));
    }
    else if (platform == 'android') {
        /* global AndroidRoutingTest */
        AndroidRoutingTest.dataRequest(JSON.stringify(dataObj));
    }
}

function pushAction(callBackId, action, callbackEvents) {
    var actionObj = {};
    actionObj.action = action;
    actionObj.actionId = callBackId;
    actionObj.callbackEvents = callbackEvents;
    actionQueue.push(actionObj);
}

window.js_globals = new Object();
window.js_globals['dataRequestCallback'] = dataRequestCallback;

function dataRequestCallback(response, iosDataObj) {
    console.log(response);
    console.log(iosDataObj);
    let dataObj = platform == "android" ? JSON.parse(response) : response;
    if (Number(dataObj.status)) {
        successCallback(dataObj, iosDataObj);
    }
    else {
        errorCallback(dataObj, iosDataObj);
    }
}

function successCallback(dataObj, iosDataObj) {
    var response;
    if (platform === 'android') {
        response = AndroidRoutingTest.successResponseCallback(dataObj.callBackId);
    }

    actionQueue.map(action => {
        if (action.actionId == dataObj.callBackId) {
            if (platform == "ios") 
                action.callbackEvents.onSuccess(iosDataObj.dataKey);
            else
                action.callbackEvents.onSuccess(response);
        }
    }) 
}

function errorCallback(dataObj, iosDataObj) {
    var response;
    if (platform === 'android') {
        response = AndroidRoutingTest.errorResponseCallback(dataObj.callBackId);
    }
    actionQueue.map(action => {
        if (action.actionId == dataObj.callBackId) {
            if (platform == "ios")
                action.callbackEvents.onError(iosDataObj);
            else
                action.callbackEvents.onError(response);
        }
    })
}