<template>
    <div>
        <v-toolbar :color="$root.themeColor ? $root.themeColor : '#395b83'" flat dark>
            <v-btn icon @click="fn_goBack" v-if="$route.query.goback">
                <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>Club Trips</v-toolbar-title>
        </v-toolbar>
        
        <template v-if="loading && !tripsData">
            <div class="pa-8">
                <v-img width="120" style="margin:auto" src="../assets/giphy.gif"></v-img>
            </div>
        </template>

        <template v-if="error">
            <div style="text-align:center;" class="pa-4">
                <p>Something went wrong. Please try again.</p>
                <br>
                <v-btn outlined @click="fn_getFirebaseToken"><v-icon left>refresh</v-icon> Retry</v-btn>
            </div>
        </template>

        <template>
        
            <div class="ml-4 mr-4 pb-4" v-if="tripsData">
                <div class="mt-4" v-for="(item, index) in tripsData.trips" :key="index">
                    <div class="title pt-2">
                        {{item.Name}}
                        <v-progress-circular class="ml-4" indeterminate color="grey" width="3" size="20" v-if="loading && selectedTripId == item.TripSiteID"></v-progress-circular>
                    </div>
                    <v-subheader dense class="pa-0">
                        {{item.Schedule}}
                        <v-spacer></v-spacer>
                        <v-chip small label color="green" text-color="white" v-if="!loading && item.TripSiteID == $root.tripSiteId">Active</v-chip>
                    </v-subheader>
                    <v-card :disabled="loading" @click="fn_getToken(item.TripSiteID)">
                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/platinumclub_homepage_logo.png"
                            v-if="item.TripSiteID == 7">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/gcamericas_homepage_logo.png"
                            v-else-if="item.TripSiteID == 8">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/GCAsia_TripBanners_Mobile_240x200.png"
                            v-else-if="item.TripSiteID == 9">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/GCEMEA_TripBanners_Mobile_240x200.png"
                            v-else-if="item.TripSiteID == 10">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/DemoTripBanner.png"
                            v-else-if="item.TripSiteID == 11">
                        </v-img>
                    </v-card>
                </div>
            </div>

            <template v-if="!loading && !error && (!tripsData || (tripsData && !tripsData.trips.length))">
                <div class="placeholder-wrapper pa-4">
                    <div center-align>
                        <v-icon class="placeholder-icon">luggage</v-icon>
                        <p class="pt-4 light-opacity-color">No trip found</p>
                        <v-btn class="mt-4" outlined @click="fn_getFirebaseToken"><v-icon left>refresh</v-icon> Reload</v-btn>
                    </div>
                </div>
            </template>
            
        </template>

    </div>
</template>

<script>

export default{
    data(){
        return{
            snackbar:true,
            tripsData:null,
            loading:false,
            error:false,
            selectedTripId:null
        }
    },
    methods:{

        fn_getFirebaseToken: function(){
            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                return;
            }

            let self = this;
            this.loading = true;
            this.error = false;
            this.getData("FirebaseToken", null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_getTrips(response)
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.error = true;
                        self.$root.fn_showToast("exception");
                    }
                }
            );
        },

        fn_getTrips: function(token){
            if(!navigator.onLine){
                this.loading = false;
                this.$root.fn_showToast("nointernet");
                return;
            }

            this.loading = true;
            this.error = false;
            
            let self = this;
            let dataObj = {};
            dataObj.Token = token;
            
            this.getServerData('GetTrips', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.loading = false;
                        self.tripsData = JSON.parse(response);
                        // self.$route.query.tsid ? self.fn_getToken(self.$route.query.tsid) : '';
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.error = true;
                        // self.$root.fn_showToast(error.Message);
                        let toastMsg = error && error.Message ? error.Message : 'exception';
                        self.$root.fn_showToast(toastMsg);
                        // self.$root.fn_showToast("exception");
                    }, 
                }
            );
        },

        fn_getToken: function(tripSiteId){

            if(this.$root.tripSiteId == tripSiteId){
                this.$root.fn_showToast("You are already logged in to this trip");
                return;
            }

            this.loading = true;

            this.selectedTripId = tripSiteId;

            let self = this;
            this.getData("FirebaseToken", null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_confirmLogin(response, tripSiteId)
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.$root.fn_showToast("exception");
                    }
                }
            );
        },

        // fn_confirmLogin: function(token){
        fn_confirmLogin: function(token, tripSiteId){
            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                return;
            }

            this.loading = true;
            
            let self = this;
            let dataObj = {};
            dataObj.TripSiteID = Number(tripSiteId);
            dataObj.DeviceID = this.tripsData.DeviceID;
            dataObj.Token = token;
            // dataObj.UserType = 1 //static for now
            
            this.postServerData('ConfirmLogin', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_reLoginToFirebase(JSON.parse(response));
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        // self.$root.fn_showToast("exception");
                        let toastMsg = error && error.Message ? error.Message : 'exception';
                        self.$root.fn_showToast(toastMsg);
                    }, 
                }
            );
        },

        fn_reLoginToFirebase: function(confirmationObj){
            let self = this;
            let dataObj = {};
            dataObj.TripSiteID = confirmationObj.TripSiteID;
            dataObj.UserType = confirmationObj.UserType ? confirmationObj.UserType : 0;
            dataObj.DeviceID = confirmationObj.DeviceID;
            dataObj.Token = confirmationObj.Token;

            this.postData("FirebaseReLoginUsingCustomToken", dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$root.fn_enterTrip(confirmationObj.TripSiteID);
                        self.loading = false;
                        console.log("Logged in Successfully");
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.$root.fn_showToast("exception");
                    }
                }
            );
        },

        // fn_enterTrip: function(tripId){
        //     let self = this;
        //     let pcgcTripId = tripId.toString();
        //     this.getData('SwitchTrip', pcgcTripId, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.$root.tripSiteId = tripId;
        //                 if(tripId == 7){
        //                     self.$root.themeColor = "#395b83";
        //                     self.$root.tripTitle = "Platinum Club"
        //                 }
        //                 else if(tripId == 8){
        //                     self.$root.themeColor = "#d64227";
        //                     self.$root.tripTitle = "AMERCICAS GOLD CLUB"
        //                 }
        //                 else if(tripId == 9){
        //                     self.$root.themeColor = "#0093c9";
        //                     self.$root.tripTitle = "EMEA GOLD CLUB"
        //                 }
        //                 else if(tripId == 10){
        //                     self.$root.themeColor = "#004987";
        //                     self.$root.tripTitle = "ASIA GOLD CLUB"
        //                 }
        //                 else if(tripId == 11){
        //                     self.$root.themeColor = "#004987";
        //                     self.$root.tripTitle = "Phuket Club Trip"
        //                 }
        //                 self.$router.replace('/homepage');
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //                 self.loading = false;
        //                 self.$root.fn_showToast("exception");
        //             }
        //         }
        //     );
        // },

        // fn_enterTrip: function(path, tripId){
        //     let self = this;
        //     let pcgcTripId = tripId.toString();
        //     this.getData('SwitchTrip', pcgcTripId, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 localStorage.removeItem('lastactivetab');
        //                 self.$router.replace('/'+path);
        //             },
        //             onError: function(error){
        //                 self.loading = false;
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },
    },
    mounted(){
        // this.fn_toast();
        // console.log(this.$route);
        // window.js_globals.fn_initActions = this.fn_initActions;
        // if(this.$route.params && this.$route.params.tripsData){
        //     this.tripsData = this.$route.params.tripsData;
        // }
        // if(this.$route.params && this.$route.params.token){
        //     this.fn_getTrips();
        // }
        // this.fn_getFirebaseToken();
        console.log(this.$route.query.tid);
        this.$root.fn_setTheme(this.$route.query.tid);
        this.$root.fn_getPersonalInfo();
    }
}
</script>